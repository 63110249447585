@import url(https://fonts.googleapis.com/css?family=Poller+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poller+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

html {
  background: url(/static/media/backgroundStars.66b83d05.jpg) no-repeat center center fixed;
  background-size: cover;
}
.Header h1 {
  text-align: center;
  font-family: 'Poller One', cursive;
  color: yellow;
  background-color: black;
  margin-top: 0;
  padding: 10px;
}

@media screen and (min-width: 505px) {
  .Header {
    font-size: 20px;
  }
}

@media screen and (min-width: 808px) {
  .Header {
    font-size: 22px;
  }
}
.Search {
  font-family: 'Lato', sans-serif;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  text-align: center;
  align-items: center;
  width: 300px;
  border: 1px solid grey;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, .7);
  color: white
}

.Search div {
  margin-bottom: 10px;
}

.Search button {
  padding: 5px;
  border-radius: 5px
}

.Search label {
  margin-right: 5px;
}

@media screen and (min-width: 505px) {
  .Search {
    font-size: 20px;
    width: 420px;
    margin-top: 30px;
  }
}

@media screen and (min-width: 808px) {
  .Search {
    font-size: 22px;
    width: 620px;
    margin-top: 40px;
  }
}
.Results {
  font-family: 'Lato', sans-serif;
  text-align: center;
  font-size: 14px;
  border: 1px solid grey;
  margin: 10px auto;
  width: 300px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, .7);
  color: white
}

.Results h2 {
  font-family: 'Poller One', cursive;
}

.Results ul {
  list-style: none;
  padding: 0 0 20px 0;
}

.Results li {
  margin-top: 10px;
}

.Results_error {
  color: red;
}

@media screen and (min-width: 505px) {
  .Results {
    font-size: 20px;
    width: 420px;
    margin-top: 30px;
  }
}

@media screen and (min-width: 808px) {
  .Results {
    font-size: 22px;
    width: 620px;
    margin-top: 40px;
  }
}
