@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

.Search {
  font-family: 'Lato', sans-serif;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  text-align: center;
  align-items: center;
  width: 300px;
  border: 1px solid grey;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, .7);
  color: white
}

.Search div {
  margin-bottom: 10px;
}

.Search button {
  padding: 5px;
  border-radius: 5px
}

.Search label {
  margin-right: 5px;
}

@media screen and (min-width: 505px) {
  .Search {
    font-size: 20px;
    width: 420px;
    margin-top: 30px;
  }
}

@media screen and (min-width: 808px) {
  .Search {
    font-size: 22px;
    width: 620px;
    margin-top: 40px;
  }
}